<template>
  <div
    id="login-page"
    class="login-page"
    :class="lang == 'en' || lang == undefined ? '' : 'text-right'"
  >
    <v-row style="height: 100vh">
      <v-col cols="12" md="4"></v-col>

      <v-col cols="12" md="4" sm="12">
        <form @:submit.prevent="onSubmit()" class="login-form" id="login-form">
          <section class="form-page" id="login-form">
            <v-img
              :src="logoPath"
              class="mb-5"
              style="max-width: 300px; margin: 0 auto"
            />
            <h6
              class="text-center form-header"
              style="color: #0275e3; font-size: 25px"
            >
              {{ $t("Control Panel") }}
            </h6>
            <p class="text-center error--text" v-if="errorMsg">
              {{ $t(errorMsg) }}
            </p>
            <div class="form-group">
              <label for="first-name">{{ $t("Username") }}</label>
              <v-text-field
                type="text"
                id="inputEmail"
                solo
                required
                :placeholder="$t('Please enter your owner email')"
                v-model.trim="$v.user.email.$model"
                @focus="onfocus()"
              ></v-text-field>

              <p
                class="error--text"
                v-if="$v.user.email.$error && !$v.user.email.required"
              >
                {{ $t("This field is Required") }}
              </p>
              <!-- <p
                class="error--text"
                v-if="$v.user.email.$error && !$v.user.email.email"
              >
                Please enter a valid email address.
              </p>-->
              <!-- <div v-if="validation_errors.en">
                <p
                  class="error--text"
                  v-for="(error, index) in validation_errors.en
                    .student_first_name"
                  :key="index"
                >
                  {{ error }}
                </p>
              </div>-->
            </div>
            <div class="form-group">
              <label for="second-name">{{ $t("Password") }}</label>
              <v-text-field
                id="inputPassword"
                solo
                required="required"
                :placeholder="$t('Please enter your owner password')"
                v-model.trim="$v.user.password.$model"
                @focus="onfocus()"
                :append-icon="value ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
              ></v-text-field>

              <p
                class="error--text"
                v-if="$v.user.password.$error && !$v.user.password.required"
              >
                {{ $t("This field is Required") }}
              </p>
            </div>
            <div class="form-group">
              <v-btn
                type="submit"
                id="submit"
                class="ui-btn submit"
                @click="submitLogin($event)"
                :disabled="$v.user.$invalid || loading"
                :loading="loading"
                large
                style="
                  border: unset !important;
                  width: 50%;
                  margin: auto;
                  display: block;
                  margin-top: 36px;
                  /* border-radius: 20px; */
                  /* margin: unset !important; */
                "
              >
                {{ $t("Login") }}
              </v-btn>
            </div>
          </section>
        </form>
      </v-col>
      <v-col cols="12" md="4"></v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
// import ACL from "../acl";
// import Acl from "../acl";
export default {
  name: "OwnerLoginView",
  data() {
    return {
      logoPath: require("../../assets/Iskola_logo.png"),
      loading: false,
      lang: this.currentAppLocale,
      value: true,
      message: "",
      user: { email: "", password: "" },
      errorMsg: "",
    };
  },
  validations: {
    user: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    resetPassword() {
      this.$router.push({ path: `/ResetPassword` });
    },
    submitLogin(event) {
      // validation form
      let newUser = {
        email: this.user.email,
        password: this.user.password,
      };
      event.preventDefault();
      this.loading = true;
      axios
        .post(this.getLandlordApiUrl + "/login", newUser)
        .then((response) => {
          this.loading = false;
          if (response.data.status.error == false) {
            //get user id from response returned
            let token = response.data.data.access_token;
            localStorage.login = true;
            localStorage.token = token;
            this.$router.push({ path: `/owner` });

            // }
          } else {
            // console.log(response);
            this.errorMsg = response.data.status.message;

            localStorage.login = false;
          }
        });
    },
    checkAuth() {
      if (localStorage.login == "true" && localStorage.token != undefined) {
        window.location = "/owner";
      }
    },
    onfocus() {
      this.errorMsg = "";
    },
  },
  created() {
    this.checkAuth();
  },
};
</script>

<style lang="scss" scoped>
// @import "../styles/_variables.scss";
@import "../../styles/_forms.scss";

.form-group .login-btn:disabled {
  cursor: not-allowed !important;
  background: webkit linear-gradient(to top, #bdc5ce, #dce3e8) !important;
  background: linear-gradient(to top, #bdc5ce, #dce3e8) !important;
}

.header-main-menu
  .navbar-nav
  .header-admin
  .dropdown-menu
  .item-content
  .settings-list
  li
  a {
  cursor: pointer !important;
}
.login-page-content .login-box .login-form .form-group {
  margin-bottom: 5px !important;
}
.dashboard-content-one {
  padding: 0 !important;
}
.forget_password {
  margin-top: -5%;
  float: right;
  text-decoration: underline;
}
.clearfix {
  overflow: auto;
  clear: both;
}
.submit {
  background-color: #0275e3 !important;
  color: #fff;
}
</style>
